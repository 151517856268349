<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static" data-keyboard="false"
    data-toggle="modal" id="bsModalSelectCompany" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
              aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">Select Company</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <v-select v-model="company" :options="companies" label="name" style="min-width: 200px;"
              placeholder="Select Company"></v-select>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" :disabled="company === null" @click="confirmCompany()">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectCompanyModal",
  data() {
    return {
      company: null,
      companies: [],
      confirmHook: () => { },
    };
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalSelectCompany").modal("hide");
    },
    confirmCompany() {
      if (this.company) {
        this.confirmHook(this.company);
        this.close();
      }
    },
  },
  mounted() {
    this.$bus.$on("selectCompanyModal", (args) => {
      this.companies = args.companies || [];
      this.confirmHook = args.confirm;
      $("#bsModalSelectCompany").modal("show");
    });
  },
};
</script>

<style scoped></style>
