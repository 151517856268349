<template>
  <div v-if="transaction && !isLoading">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">{{ transaction.Prefix === 'T' ? 'Transaction' : 'Payout' }} Detail</h3>
          <div v-if="transaction.Prefix === 'T'" class="flex align-items-center">
            <a v-if="ecwidStoreUrl" :href="ecwidStoreUrl" v-tooltip="{ content: 'ECWID Store', trigger: 'hover focus' }"
              class="mr-8" target="_blank">
              <i class="fa fa-globe fa-2x"></i>
            </a>
            <a class="pointer mr-8" @click="selectInvoiceCompany()">
              <i class="fa fa-lg fa-file-pdf-o"></i>
            </a>
            <a :href="'https://cointopay.com/invoice/' + transaction.Security" class="mr-8" target="_blank">Invoice
              Public Url</a>
            <a v-tooltip="{ content: 'Invoice Public URL', trigger: 'click hover focus' }" class="nav-action"
              style="color: #007bff;" @click="copyInvoiceURL(transaction.Security)">
              <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
              </svg>
            </a>
          </div>
          <div class="flex align-items-center" v-else>
            <a class="pointer mr-8" @click="selectInvoiceCompany()">
              <i class="fa fa-lg fa-file-pdf-o"></i>
            </a>
            <a :href="'https://cointopay.com/payout/' + transaction.Security" class="mr-8" target="_blank">Invoice
              Public Url</a>
            <a v-tooltip="{ content: 'Invoice Public URL', trigger: 'click hover focus' }" class="nav-action"
              style="color: #007bff;" @click="copyInvoiceURL(transaction.Security)">
              <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="box-body" style="padding: 0 15px">
        <div class="row">
          <div class="col-md-2">Merchant ID</div>
          <div class="col-md-10">
            <router-link :to="{ name: 'user-detail', params: { id: transaction.MerchantID } }" tag="a">
              {{ transaction.MerchantID }}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">{{ transaction.Prefix === 'P' ? 'Payout ID' : 'Transaction ID' }}</div>
          <div class="col-md-10">
            <router-link tag="a" :to="{ name: 'payouts', hash: hash, query: { keyword: transaction.TransactionID } }"
              v-if="transaction.Prefix === 'P'">
              {{ transaction.TransactionID }}
            </router-link>
            <span v-else>{{ transaction.TransactionID }}</span>
            <span class="ml-8">
              <i class="fa fa-clipboard pointer" @click="copyToClipboard(transaction.TransactionID)"></i>
            </span>
          </div>
        </div>
        <div v-if="transaction.MasterTransactionID && transaction.MasterTransactionID !== transaction.TransactionID"
          class="row">
          <div class="col-md-2">Master Transaction ID</div>
          <div class="col-md-10">
            <router-link
              :to="{ name: 'transaction-detail', params: { id: formatMasterID(transaction.MasterTransactionID) } }"
              tag="a">
              {{ transaction.MasterTransactionID }}
            </router-link>
          </div>
        </div>
        <div v-if="transaction.SubTransactions" class="row">
          <div class="col-md-2">Sub Transactions</div>
          <div class="col-md-10">
            <span v-for="(id, index) in subTransactions" :key="index">
              <label class="label label-default mr-4">
                <router-link :to="{ name: 'transaction-detail', params: { id: formatMasterID(id) } }" tag="a">
                  {{ id }}
                </router-link>
              </label>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Confirm Code</div>
          <div class="col-md-10">
            <div class="input-group" style="max-width: 300px;">
              <input aria-label="" style="height:29px;" type="text" readonly id="security" class="form-control"
                :value="transaction.Security" />
              <div class="input-group-btn">
                <button class="btn btn-primary btn-sm" @click="copyConfirmCode()">Copy</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Merchant</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
              <router-link :to="{ name: 'user-detail', params: { id: transaction.MerchantID } }" tag="a">
                {{ transaction.ShopTitle }}
              </router-link>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Username</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
              <router-link :to="{ name: 'user-detail', params: { id: transaction.MerchantID } }" tag="a">
                {{ transaction.OwnerNickName }}
              </router-link>
            </span>
          </div>
        </div>
        <div v-if="transaction.RegisteredEmail" class="row">
          <div class="col-md-2">Registered Email</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
              {{ transaction.RegisteredEmail + '@' + transaction.XChangeServer }}
            </span>
          </div>
        </div>
        <div v-if="isAuthorized('admin-commander') && !isSecureCloud && transaction.XChangeID !== 0" class="row">
          <div class="col-md-2">XChangeID</div>
          <div class="col-md-10">
            <router-link
              :to="{ name: 'coin-health-detail', params: { id: transaction.AltCoinID }, query: { q: 'xChange', id: transaction.XChangeID } }"
              tag="a">
              {{ 'X' + transaction.XChangeID }}
            </router-link>
          </div>
        </div>
        <div v-if="transaction.Prefix === 'T'" class="row">
          <div class="col-md-2">Initial Amount</div>
          <div class="col-md-10">
            {{ transaction.InitialAmount + ' ' + transaction.LongName }}
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.InitialAmount), transaction.AltCoinID)"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Amount</div>
          <div class="col-md-10">
            <router-link tag="a" :to="{ name: 'coin-health-detail', params: { id: transaction.AltCoinID } }">
              {{ toFixed(transaction.Amount) + ' ' + transaction.LongName }}
              <img :src="generateIconUrl(transaction.CoinName)" alt="" class="ml-8 w-20" />
            </router-link>
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.Amount), transaction.AltCoinID)"></i>
            </span>
            <span class="ml-8">
              <i class="fa fa-edit pointer" @click="updateTransaction('Amount', 'Amount', 'text')"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">Network Confirmed Amount</div>
          <div class="col-md-10">
            {{ transaction.NetworkConfirmedAmount + ' ' + transaction.LongName }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Original Amount</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('OriginalAmount', 'Original Amount', 'text')">
              {{ transaction.OriginalAmount + ' ' + transaction.InputCurrency }}
            </span>
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.OriginalAmount), shortNameToID(transaction.InputCurrency))"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">Original Amount Fee</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('OriginalAmountFee', 'Original Amount Fee', 'text')">
              {{ transaction.OriginalAmountFee + ' ' + transaction.InputCurrency }}
            </span>
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.OriginalAmountFee), shortNameToID(transaction.InputCurrency))"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">Fiat Confirmed Amount</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('FiatConfirmedAmount', 'Fiat Confirmed Amount', 'text')">
              {{ transaction.FiatConfirmedAmount + ' EUR' }}
            </span>
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.FiatConfirmedAmount), shortNameToID('EUR'))"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'P'">
          <div class="col-md-2">Amount Fiat Value</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('AmountFiatValue', 'Amount Fiat Value', 'text')">
              {{ transaction.AmountFiatValue + ' ' + transaction.InputCurrency }}
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'P'">
          <div class="col-md-2">Fiat Amount</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('FiatAmount', 'Fiat Amount', 'text')">
              {{ transaction.FiatAmount + ' ' + transaction.FiatCurrency }}
            </span>
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.FiatAmount), shortNameToID(transaction.FiatCurrency))"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">EUR Amount</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('EURAmount', 'EUR Amount', 'text')">{{ transaction.EURAmount
    +
    ' EUR' }}</span>
            <span class="ml-8">
              <i class="fa fa-clipboard pointer" @click="copyToClipboard(transaction.EURAmount)"></i>
            </span>
          </div>
        </div>
        <div v-if="transaction.NotEnough === 1 && transaction.Status.toLowerCase() === 'underpaid'" class="row">
          <div class="col-md-2">Missing Amount</div>
          <div class="col-md-10 flex align-items-center">
            {{ transaction.MissingAmount + ' ' + transaction.LongName }}
            <a v-if="!transaction.MissingAmountSecurity" class="ml-8" @click="generateTransaction()">
              <span v-if="loader !== 'generateTransaction'">
                Generate Transaction of Missing amount: {{ transaction.MissingAmount + ' ' + transaction.LongName }}
              </span>
              <i v-if="loader === 'generateTransaction'" class="fa fa-circle-o-notch fa-spin"></i>
            </a>
            <div v-if="transaction.MissingAmountSecurity" class="ml-8 flex align-items-center">
              <span>Missing amount invoice</span>
              <a v-tooltip="{ content: 'Invoice Public URL', trigger: 'click hover focus' }" class="nav-action ml-8"
                @click="copyInvoiceURL(transaction.MissingAmountSecurity)">
                <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div v-if="transaction.MinerFee" class="row">
          <div class="col-md-2">Miner Fee</div>
          <div class="col-md-10">
            {{ transaction.MinerFee + ' ' + transaction.LongName }}
            <span class="ml-8">
              <i class="fa fa-copy pointer"
                @click="toConverter(toFixed(transaction.MinerFee), transaction.AltCoinID)"></i>
            </span>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">Freeze Auto Confirmations</div>
          <div class="col-md-10">
            <label :class="`label ${transaction.TransactionConfirmedFreeze ? 'label-success' : 'label-danger'}`"
              @click="toggleTransactionBooleanFields('TransactionConfirmed_Freeze', transaction.TransactionConfirmedFreeze)">
              {{ transaction.TransactionConfirmedFreeze ? 'Yes' : 'No' }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Type</div>
          <div class="col-md-10">
            {{
    transaction.ExternalTransactionProvider ? transaction.ExternalTransactionProvider :
      transaction.TransactionType
  }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Status</div>
          <div class="col-md-10 flex">
            <span v-if="transaction.Prefix === 'P'" v-html="fs(transaction.Status)"></span>
            <span v-if="transaction.Prefix !== 'P'" @click="launchTransactionStatusModal()"
              v-html="fs(transaction.Status)"></span>
            <a class="cursor-pointer" @click="refreshTransaction()" v-if="isRefreshable && !isPaid && !isExpired">
              <status-icon :transaction="transaction"></status-icon>
            </a>
          </div>
        </div>
        <div class="row" v-if="transaction.Prefix === 'T'">
          <div class="col-md-2">Exchange ID</div>
          <div class="col-md-10">
            <span @click="showExchangeDetail(transaction.ExchangeID)" class="pointer">
              {{ transaction.ExchangeID }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Customer Reference</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''} pointer`"
              @click="updateTransaction('CustomerReferenceNr', 'Customer Reference', 'text')">
              {{ transaction.CustomerReferenceNr || '-' }}
            </span>
            <span class="ml-8">
              <i class="fa fa-clipboard pointer" @click="copyToClipboard(transaction.CustomerReferenceNr)"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Comments</div>
          <div class="col-md-10 col-lg-6">
            <button class="btn btn-primary btn-sm btn-flat mb-8" @click="launchUpdateTransactionLogsModal(transaction)">
              Update Comments
            </button>
            <div class="textarea pointer" v-html="transactionComments"
              @click="updateTransaction('Comments', 'Comments', 'textarea')"></div>
          </div>
        </div>
        <div v-if="transaction.Prefix === 'T'" class="row">
          <div class="col-md-2">Refund Address</div>
          <div class="col-md-10">
            <a v-if="isRefundable" href="javascript:void(0)" @click="launchRefundAddressModal()">
              Add/Update Refund Address
            </a>
            <button type="button" class="btn btn-primary btn-sm ml-4" :disabled="loader !== ''"
              v-if="transaction.RefundAddress && transaction.RefundAddress.toLowerCase() !== 'empty' && (transaction.Status === 'paid' || transaction.Status === 'underpaid')"
              @click="refund(transaction)">
              Refund
            </button>
            <div class="mb-2" v-if="selectedAddress !== null">
              <p v-for="(item, key) in selectedAddress" :key="key" style="margin-bottom: 4px;">
                <span class="capitalize">{{ format(key) }}</span>
                {{ ': ' + (key === 'altCoinID' ? getCoinName(item) : item) }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="isCoinAddress" class="row">
          <div class="col-md-2">Coin Address</div>
          <div class="col-md-10">
            <a v-if="transaction.BlockExplorer" class="wb"
              :href="transaction.BlockExplorer + decodeURIComponent(transaction.CoinAddress)" target="_blank">{{
    decodeURIComponent(transaction.CoinAddress) }}</a>
            <span v-if="!transaction.BlockExplorer" class="wb">{{ transaction.CoinAddress }}</span>
            <span class="ml-8 wb">
              <i class="fa fa-copy pointer" @click="copyToClipboard(transaction.CoinAddress)"></i>
            </span>
          </div>
        </div>
        <div v-if="transaction.Tag" class="row">
          <div class="col-md-2">Memo/Tag</div>
          <div class="col-md-10">{{ transaction.Tag }}</div>
        </div>
        <div v-if="transaction.InvoiceCC" class="row">
          <div class="col-md-2">Invoice CC</div>
          <div class="col-md-10">{{ transaction.InvoiceCC }}</div>
        </div>
        <div v-if="transaction.Prefix === 'T'" class="row">
          <div class="col-md-2">Reopenable</div>
          <div class="col-md-10">
            <div class="d-inline-block mr-8" v-html="fs(transaction.Reopenable)"
              @click="updateTransaction('Reopenable', 'Reopenable', 'select')"></div>
            <div v-if="transaction.Reopenable && transaction.Status.toLowerCase() !== 'waiting'"
              class="d-inline-block mr-8">
              <a class="pointer" @click="reOpenTransaction()">
                Re-open transaction
                <span v-if="loader === 'reopen'" class="ml-8">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </span>
              </a>
            </div>
            <div v-if="transaction.Status.toLowerCase() !== 'waiting' && isAuthorized('admin-commander')"
              class="d-inline-block">
              <a class="pointer" @click="reOpenAdminMode()">
                Re-open Admin Mode
                <span v-if="loader === 'reopen-admin-mode'" class="ml-8">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div v-if="transaction.ConfirmURL" class="row">
          <div class="col-md-2">Confirm URL</div>
          <div class="col-md-10">
            <div class="d-flex align-items-center">
              <a v-if="ecwidStoreUrl" :href="ecwidStoreUrl"
                v-tooltip="{ content: 'ECWID Store', trigger: 'hover focus' }" class="mr-8" target="_blank">
                <i class="fa fa-globe"></i>
              </a>
              <span class="d-block" :class="`${isPrivacyMode ? 'blurred' : ''}`">
                {{ transaction.ConfirmURL }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="transaction.FailURL" class="row">
          <div class="col-md-2">Failure URL</div>
          <div class="col-md-10">
            <div class="d-flex align-items-center">
              <a v-if="ecwidStoreUrl" :href="ecwidStoreUrl"
                v-tooltip="{ content: 'ECWID Store', trigger: 'hover focus' }" class="mr-8" target="_blank">
                <i class="fa fa-globe"></i>
              </a>
              <span class="d-block" :class="`${isPrivacyMode ? 'blurred' : ''}`">
                {{ transaction.FailURL }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="transaction.CustomerIP" class="row">
          <div class="col-md-2">Customer</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
              {{ transaction.CustomerIP + ' - ' + transaction.CustomerCountry }}
            </span>
          </div>
        </div>
        <div v-if="transaction.EndCustomerIP" class="row">
          <div class="col-md-2">End Customer</div>
          <div class="col-md-10">
            <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
              {{ transaction.EndCustomerIP + ' - ' + transaction.EndCustomerCountry }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">Created On</div>
          <div class="col-md-10">{{ fd(transaction.CreatedOn) }}</div>
        </div>
        <div class="row" v-if="transaction.UpdatedOn">
          <div class="col-md-2">Updated On</div>
          <div class="col-md-10">{{ fd(transaction.UpdatedOn) }}</div>
        </div>
        <div v-if="transaction.ExpiredDate" class="row">
          <div class="col-md-2">Expired On</div>
          <div class="col-md-10">
            <span class="pointer" @click="updateTransaction('ExpiredDate', 'Expired On', 'date')">
              {{ fd(transaction.ExpiredDate) }}
            </span>
          </div>
        </div>
        <div v-if="transaction.Confirmed && transaction.TransactionConfirmedOn" class="row">
          <div class="col-md-2">Confirmed On</div>
          <div class="col-md-10">{{ fd(transaction.TransactionConfirmedOn) }}</div>
        </div>
        <div v-if="isOrderStatusUpdateAvailable" class="row">
          <div class="col-md-2">Order Status</div>
          <div class="col-md-10">
            <span v-if="!isEditableCartStatus" class="pointer" @click="isEditableCartStatus = true">{{
    cartStatus(transaction.CartStatusID) }}
            </span>
            <div v-if="isEditableCartStatus" class="row">
              <div class="col-md-4">
                <div class="flex justify-between">
                  <select v-model="formCartStatus.CartStatusID" aria-label="" class="form-control">
                    <option v-for="(status, index) in cartStatusList" :key="index" :value="status.id">
                      {{ status.value }}
                    </option>
                  </select>
                  <button class="btn btn-primary btn-flat btn-square ml-8" type="button" @click="updateCartStatus()">
                    <svg v-if="loader !== 'updateCart'" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <i v-if="loader === 'updateCart'" class="fa fa-circle-o-notch fa-spin"></i>
                  </button>
                  <button class="btn btn-square btn-danger btn-flat ml-8" type="button"
                    @click="isEditableCartStatus = false">
                    <svg class="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!invoice && transaction.Prefix === 'T'">
          <div class="col-md-12">
            <button type="button" @click="loadHtml()" class="btn btn-primary btn-flat" :disabled="isHtmlBtnLoading">
              <span>Load Html</span>
              <i class="fa fa-spinner fa-spin ml-4" v-if="isHtmlBtnLoading"></i>
            </button>
          </div>
        </div>
        <template v-if="invoice">
          <div class="row">
            <div class="col-sm-2">Payment Detail</div>
            <div class="col-sm-10">
              <div v-html="invoice.PaymentDetail"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">Payment Detail CC</div>
            <div class="col-sm-10">
              <div v-html="invoice.PaymentDetailCConly"></div>
            </div>
          </div>
        </template>
        <template v-if="transaction.Prefix === 'P' && transaction.TransactionType.toLowerCase() !== 'payout cancelled'">
          <div class="row">
            <div class="col-sm-12 text-bold">Payout Information -- {{ isPayoutType(transaction) }}</div>
          </div>
          <template v-if="isPayoutType(transaction) === 'Bank'">
            <div v-if="transaction.PayoutName" class="row">
              <div class="col-md-2">Account Holder Name</div>
              <div class="col-md-2">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PayoutName }}</span>
              </div>
            </div>
            <div v-if="transaction.PayoutIBAN" class="row">
              <div class="col-md-2">IBAN/Account Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PayoutIBAN }}</span>
              </div>
            </div>
            <div v-if="transaction.AccountType" class="row">
              <div class="col-md-2">Account Type</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.AccountType.toLowerCase() }}</span>
              </div>
            </div>
            <div v-if="transaction.TaxId" class="row">
              <div class="col-md-2">Tax Id: CUIL / CUIT</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.TaxId }}</span>
              </div>
            </div>
            <div v-if="transaction.BsbCode" class="row">
              <div class="col-md-2">BSC Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.BsbCode }}</span>
              </div>
            </div>
            <div v-if="transaction.PayoutBIC" class="row">
              <div class="col-md-2">BIC/Swift/Routing code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PayoutBIC }}</span>
              </div>
            </div>
            <div v-if="transaction.BranchCode" class="row">
              <div class="col-md-2">Branch Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.BranchCode }}</span>
              </div>
            </div>
            <div v-if="transaction.CPF" class="row">
              <div class="col-md-2">Recipient tax ID/CPF</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.CPF }}</span>
              </div>
            </div>
            <div v-if="transaction.PhoneNumber" class="row">
              <div class="col-md-2">Account Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PhoneNumber }}</span>
              </div>
            </div>
            <div v-if="transaction.InstitutionNumber" class="row">
              <div class="col-md-2">Institution Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.InstitutionNumber }}</span>
              </div>
            </div>
            <div v-if="transaction.TransitCode" class="row">
              <div class="col-md-2">Transit Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.TransitCode }}</span>
              </div>
            </div>
            <div v-if="transaction.RUT" class="row">
              <div class="col-md-2">Beneficiary's RUT number (Rol Único Tributario)</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.RUT }}</span>
              </div>
            </div>
            <div v-if="transaction.CardNumber" class="row">
              <div class="col-md-2">UnionPay card number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.CardNumber }}</span>
              </div>
            </div>
            <div v-if="transaction.SortCode" class="row">
              <div class="col-md-2">Sort Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.SortCode }}</span>
              </div>
            </div>
            <div v-if="transaction.IfscCode" class="row">
              <div class="col-md-2">IFSC Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.IfscCode }}</span>
              </div>
            </div>
            <div v-if="transaction.Clabe" class="row">
              <div class="col-md-2">Recipient CLABE Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.Clabe }}</span>
              </div>
            </div>
            <div v-if="transaction.SwiftCode" class="row">
              <div class="col-md-2">Swift Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.SwiftCode }}</span>
              </div>
            </div>
            <div v-if="transaction.IdDocumentType" class="row">
              <div class="col-md-2">ID Document Type</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.IdDocumentType }}</span>
              </div>
            </div>
            <div v-if="transaction.IdDocumentNumber" class="row">
              <div class="col-md-2">Identification Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
                  {{ transaction.IdDocumentNumber }}
                </span>
              </div>
            </div>
            <div v-if="transaction.Abartn" class="row">
              <div class="col-md-2">Routing Number</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.Abartn }}</span>
              </div>
            </div>
            <div v-if="transaction.FirstLine" class="row">
              <div class="col-md-2">Address</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.FirstLine }}</span>
              </div>
            </div>
            <div v-if="transaction.City" class="row">
              <div class="col-md-2">City</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.City }}</span>
              </div>
            </div>
            <div v-if="transaction.State" class="row">
              <div class="col-md-2">State</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.State }}</span>
              </div>
            </div>
            <div v-if="transaction.PostCode" class="row">
              <div class="col-md-2">Postal Code</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PostCode }}</span>
              </div>
            </div>
            <div v-if="transaction.Country" class="row">
              <div class="col-md-2">Country</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.Country }}</span>
              </div>
            </div>
          </template>
          <template v-if="isPayoutType(transaction) === 'Payeer'">
            <div v-if="transaction.PayoutTo" class="row">
              <div class="col-md-2">Payeer</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PayoutTo }}</span>
              </div>
            </div>
          </template>
          <template v-if="isPayoutType(transaction) === 'PayPal'">
            <div v-if="transaction.PayoutTo" class="row">
              <div class="col-md-2">PayPal</div>
              <div class="col-md-10">
                <span :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ transaction.PayoutTo }}</span>
              </div>
            </div>
          </template>
          <template v-if="isPayoutType(transaction) === 'Crypto'">
            <div class="row">
              <div class="col-md-2">Crypto Currency</div>
              <div class="col-md-10">{{ transaction.CoinName + ' - ' + transaction.LongName }}</div>
            </div>
            <div class="row">
              <div class="col-md-2">Address</div>
              <div class="col-md-10">
                <a v-if="transaction.BlockExplorer" class="wb"
                  :href="transaction.BlockExplorer + decodeURIComponent(transaction.CoinAddress)" target="_blank">{{
    decodeURIComponent(transaction.CoinAddress) }}</a>
                <span v-else class="wb">{{ transaction.CoinAddress }}</span>
                <span class="ml-8">
                  <i class="fa fa-copy pointer" @click="copyToClipboard(transaction.CoinAddress)"></i>
                </span>
              </div>
            </div>
          </template>
        </template>
        <div class="row">
          <div class="col-md-12">
            <h3 class="sub-title" style="margin-top: 0">Forward Invoice</h3>
          </div>
          <div class="col-md-5">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(forwardInvoice)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="email" rules="required|email"
                  tag="div">
                  <label for="email">Email Address</label>
                  <div class="input-group">
                    <input id="email" v-model="formForwardInvoice.email" class="form-control" type="text" />
                    <div class="input-group-btn">
                      <button :disabled="loader === 'forwardInvoice'" class="btn btn-primary btn-flat" type="submit">
                        Forward Invoice
                        <i v-if="loader === 'forwardInvoice'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
                      </button>
                    </div>
                  </div>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </form>
            </ValidationObserver>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h3 class="sub-title" style="margin-top: 0">{{ transaction.Prefix === 'T' ? 'Transaction' : 'Payout' }}
              Actions</h3>
          </div>
          <div class="col-md-12">
            <div v-if="transaction.Prefix === 'T'">
              <button class="btn btn-primary btn-flat" @click="reportTransaction('reportTransaction')">
                Report Transaction
                <i v-if="loader === 'reportTransaction'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </button>
              <button class="btn btn-primary btn-flat ml-8" @click="sendMail()">
                Send Mail
                <i v-if="loader === 'sendMail'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </button>
              <button class="btn btn-primary btn-flat ml-8" @click="confirmationLink()">
                Confirmation Link
                <i v-if="loader === 'confirmationLink'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </button>
              <button class="btn btn-primary btn-flat ml-8" @click="launchTransactionStatusModal()">Update Transaction
                Status
              </button>
              <button :disabled="!isRefundable" class="btn btn-primary btn-flat ml-8" @click="refund(transaction)">
                Refund
              </button>
            </div>
            <div v-if="transaction.Prefix === 'P'">
              <button class="btn btn-primary btn-flat" @click="reportTransaction('reportPayout')">
                Report Payout
                <i v-if="loader === 'reportPayout'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </button>
              <button class="btn btn-primary btn-flat ml-8" @click="reversePayout()">
                Reverse Payout
                <i v-if="loader === 'reversePayout'" class="fa fa-circle-o-notch fa-spin ml-8"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Update transaction status modal -->
    <update-transaction-status-modal></update-transaction-status-modal>
    <!-- Update transaction logs modal -->
    <update-transaction-logs-modal></update-transaction-logs-modal>
    <!-- Exchange Detail Modal -->
    <exchange-detail-modal></exchange-detail-modal>
    <!-- Update Transaction Modal -->
    <update-transaction-modal></update-transaction-modal>
    <!-- Swap Modal -->
    <SwapModal />
    <!-- Refund Address Modal -->
    <RefundAddressModal></RefundAddressModal>
    <SelectCompanyModal></SelectCompanyModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING, SAVE_CONVERTER, SET_EXTERNAL_PAYMENT_PROVIDERS, SET_PAYOUT_TYPES, TRANSFERWISE_CONFIG } from "@/store/keys";
import { formatDate, formatStatus, isAuthorized, generateIconUrl, logout, saveNotification, toFixed, getOptions } from "@/lib/utils";
import UpdateTransactionStatusModal from "@/components/modals/UpdateTransactionStatusModal.vue";
import UpdateTransactionLogsModal from "@/components/modals/UpdateTransactionLogsModal.vue";
import ExchangeDetailModal from "@/components/modals/ExchangeDetailModal.vue";
import UpdateTransactionModal from "@/components/modals/UpdateTransactionModal.vue";
import RefundAddressModal from "@/components/modals/RefundAddressModal.vue";
import SwapModal from "@/components/modals/SwapModal.vue";
import StatusIcon from "@/components/StatusIcon.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import SelectCompanyModal from "@/components/modals/SelectCompanyModal.vue";

export default {
  name: "TransactionDetail",
  components: {
    UpdateTransactionModal,
    ExchangeDetailModal,
    UpdateTransactionLogsModal,
    UpdateTransactionStatusModal,
    RefundAddressModal,
    SwapModal,
    StatusIcon,
    SelectCompanyModal,
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_ADMIN_API_URL,
      transaction: null,
      formForwardInvoice: {
        email: "",
      },
      formRefundAddress: {
        RefundAddress: "",
      },
      formCartStatus: {
        CartStatusID: "",
      },
      isEditableCartStatus: false,
      isEditableRefundAddress: false,
      loader: "",
      isRefreshable: false,
      selectedAddress: null,
      isHtmlBtnLoading: false,
      invoice: null,
      balanceList: [],
      hasBalance: false,
      refundTransaction: null,
      isRefundProcessing: false,
      pdfConfig: [],
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "jwtToken",
      "cartStatusList",
      "converter",
      "altCoins",
      "externalPaymentProviders",
      "payoutTypes",
      "isPrivacyMode",
    ]),
    isCoinAddress() {
      return this.transaction.CoinAddress && this.transaction.CoinAddress !== "NA" &&
        this.transaction.CoinAddress !== "none";
    },
    isOrderStatusUpdateAvailable() {
      return this.transaction && this.transaction.Prefix === "T" &&
        (this.transaction.Status === "paid" || this.transaction.Status === "underpaid") &&
        this.transaction.Buy === 0;
    },
    subTransactions() {
      if (this.transaction.SubTransactions) {
        return this.transaction.SubTransactions.split(",").filter(Boolean);
      }
      return [];
    },
    isSecureCloud() {
      const type = this.transaction.ExternalTransactionProvider
        ? this.transaction.ExternalTransactionProvider : this.transaction.TransactionType;
      return type === "SecureCloud";
    },
    transactionComments() {
      return this.transaction.Comments ? this.transaction.Comments.replace(/\n/g, "<br/>") : "NA";
    },
    isRefundable() {
      return (
        this.transaction && this.transaction.Prefix === "T" &&
        (this.transaction.Status === "paid" || this.transaction.Status === "underpaid") &&
        this.transaction.TransactionType.toLowerCase() !== "swap"
      );
    },
    hash() {
      let hash = "completed";
      if (this.transaction) {
        if (this.transaction.Status.toLowerCase().indexOf("cancelled") !== -1) {
          hash = "cancelled";
        }
      }
      return "#" + hash;
    },
    isPaid() {
      return this.transaction && ["paid", "underpaid"].indexOf(this.transaction.Status.toLowerCase()) !== -1;
    },
    isExpired() {
      return this.transaction && this.transaction.Status.toLowerCase() === "expired";
    },
    ecwidStoreUrl() {
      if (this.transaction && this.transaction.ConfirmURL && this.transaction.ConfirmURL.indexOf("ecwid") !== -1) {
        const parts = this.transaction.ConfirmURL.split("?");
        if (parts.length >= 2) {
          const urlParams = new URLSearchParams(parts[1]);
          return `https://store${urlParams.get("storeId")}.company.site/`;
        }
      }
      return "";
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.getTransaction(to.params.id);
      }
    },
  },
  methods: {
    toFixed(val) {
      return toFixed(val);
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    shortNameToID(shortName) {
      const coin = this._.find(this.altCoins, { ShortName: shortName });
      return coin ? coin.ID : 625;
    },
    fs(status) {
      return formatStatus(status);
    },
    fd(val) {
      return formatDate(val);
    },
    formatMasterID(id) {
      return "T" + id.toString().padStart(9, "0");
    },
    generateIconUrl(name) {
      return generateIconUrl(name);
    },
    isAuthorized(permission) {
      return isAuthorized(permission);
    },
    isPayoutType(payout) {
      let type = "Crypto";
      const payoutType = Number.parseInt(payout.TransactionTypeID);
      if (payoutType === 11) {
        type = "PayPal";
      } else if (payoutType === 17) {
        type = "Payeer";
      } else if (payout.isBankPayout) {
        type = "Bank";
      }
      return type;
    },
    unflattenObject(obj) {
      const result = {};

      for (const key in obj) {
        const keys = key.split(".");
        keys.reduce((acc, currentKey, index) => {
          if (index === keys.length - 1) {
            acc[currentKey] = obj[key];
          } else {
            acc[currentKey] = acc[currentKey] || {};
            return acc[currentKey];
          }
        }, result);
      }

      return result;
    },
    formatRefundAddress() {
      this.selectedAddress = {};
      if (this.transaction.RefundAddress && this.transaction.RefundAddress.toLowerCase() !== "empty") {
        const parts = this.transaction.RefundAddress.split("-");
        parts.forEach(p => {
          p = p.split(":");
          let key = p[0];
          if (p[0].indexOf(".") !== -1) {
            key = p[0].split(".");
            key = key[key.length - 1];
          }
          this.selectedAddress[key] = p[1];
        });
      }
    },
    loadHtml() {
      this.isHtmlBtnLoading = true;
      const apiUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "Transactiondetail",
        MerchantID: 1,
        APIKey: "_",
        ConfirmCode: this.transaction.Security,
        output: "json",
      };
      this.$http.get(`${apiUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.isHtmlBtnLoading = false;
        if (response.status === 200) {
          if (response.data.result !== "failed") {
            this.invoice = response.data.data;
          }
        }
      }).catch(error => {
        this.isHtmlBtnLoading = false;
        console.log(error);
      });
    },
    getTransaction(id, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      this.$http.get(`${this.apiBaseUrl}/?Call=TransactionDetail`, {
        params: {
          ID: id,
        },
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        if (response.status === 200) {
          this.transaction = response.data.data;
          if (this.transaction.Prefix === "T") {
            this.formCartStatus.CartStatusID = this.transaction.CartStatusID;
            this.formRefundAddress.RefundAddress = this.transaction.RefundAddress;
          }
          this.formatRefundAddress();
          this.getWalletsBalance();
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        if (error && error.data && error.data.message) {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.data.message);
        }
      });
    },
    copyInvoiceURL(security) {
      const el = document.createElement("textarea");
      el.value = "https://cointopay.com/" + (this.transaction.Prefix === "T" ? "invoice/" : "payout/") + security;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$toast.fire("", "Copied!", "success");
    },
    copyConfirmCode() {
      const el = document.getElementById("security");
      const selected =
        document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand("copy");
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$toast.fire("", "Copied!", "success");
    },
    generateTransaction() {
      this.loader = "generateTransaction";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Checkout: true,
        MerchantID: this.transaction.MerchantID,
        SecurityCode: this.transaction.SecurityCode,
        CustomerReferenceNr: this.transaction.CustomerReferenceNr,
        AltCoinID: this.transaction.AltCoinID,
        inputCurrency: this.transaction.ShortName,
        Amount: this.transaction.MissingAmount,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/MerchantAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        const transaction = response.data;
        if (transaction) {
          this.transaction.MissingAmountSecurity = transaction.Security;
          this.$toast.fire("", "Transaction created successfully", "success");
          saveNotification("Transaction created successfully");
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    forwardInvoice() {
      this.loader = "forwardInvoice";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/InvoiceForwarder`, {
        params: {
          invoiceAddress: this.formForwardInvoice.email,
          target: false,
          shop: this.transaction.ShopTitle,
          TransactionID: this.transaction.TransactionID,
          coinAddress: this.transaction.CoinAddress,
          qrcode: this.transaction.CoinName.toLowerCase() + ":" + this.transaction.CoinAddress,
          amount: this.transaction.Amount,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.data.toLowerCase() === "mail sent") {
          this.resetFormForwardInvoice();
          this.$toast.fire("", "Invoice sent successfully", "success");
          saveNotification("Invoice sent successfully");
        }
      }).catch(error => {
        this.loader = "";
        // Display toast error
        console.log(error);
      });
    },
    resetFormForwardInvoice() {
      this.formForwardInvoice.email = "";
      this.$nextTick(() => this.$refs.observer.reset());
    },
    reOpenTransaction() {
      this.loader = "reopen";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Reopen",
          MerchantID: this.transaction.MerchantID,
          APIKey: this.transaction.APIKey,
          ConfirmCode: this.transaction.Security,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          this.transaction.Status = "waiting";
          this.$toast.fire("", "Transaction scheduled for re-validation", "success");
          saveNotification("Transaction scheduled for re-validation");
        } else {
          this.$toast.fire("", response.data.message, "error");
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    reOpenAdminMode() {
      this.loader = "reopen-admin-mode";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "ReopenAdminMode",
          MerchantID: this.transaction.MerchantID,
          APIKey: this.transaction.APIKey,
          ConfirmCode: this.transaction.Security,
          output: "json",
        },
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          this.transaction.Status = "waiting";
          this.$toast.fire("", "Transaction scheduled for re-validation", "success");
          saveNotification("Transaction scheduled for re-validation");
        } else {
          this.$toast.fire("", response.data.message, "error");
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    cartStatus(cartStatus) {
      return this._.find(this.cartStatusList, { id: cartStatus })?.value;
    },
    updateCartStatus() {
      this.loader = "updateCart";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "UpdateCartStatus",
        MerchantID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
        TransactionID: this.transaction.TransactionID,
        CartStatusID: this.formCartStatus.CartStatusID,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          this.transaction.CartStatusID = this.formCartStatus.CartStatusID;
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.isEditableCartStatus = false;
        }
      }).catch(error => {
        this.loader = "";
        // TODO: toast error message
        console.log(error);
      });
    },
    launchRefundAddressModal() {
      this.$bus.$emit("updateRefundAddressModal", {
        title: "Refund Address",
        data: this.transaction,
        confirm: (info) => {
          if (info) {
            this.updateRefundAddress(info);
          } else {
            this.updateRefundAddress("EMPTY");
          }
        },
      });
    },
    updateRefundAddress(refundAddress) {
      this.loader = "updateRefundAddress";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "Refund",
        MerchantID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
        RefundAddress: refundAddress,
        ConfirmCode: this.transaction.Security,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data.result === "success") {
          this.transaction.RefundAddress = refundAddress;
          this.$toast.fire("", "Refund address added successfully", "success");
          saveNotification("Refund address added successfully");
          this.isEditableRefundAddress = false;
          this.formatRefundAddress();
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    reportTransaction(type) {
      this.loader = type;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        FIU: "",
        Call: "intSwitch",
        MerchantID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
        ConfirmCode: this.transaction.Security,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    sendMail() {
      this.loader = "sendMail";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        SendMail: "",
        Call: "intSwitch",
        MerchantID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
        ConfirmCode: this.transaction.Security,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    confirmationLink() {
      this.loader = "confirmationLink";
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        ConfirmationLink: "",
        Call: "intSwitch",
        MerchantID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
        ConfirmCode: this.transaction.Security,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    launchTransactionStatusModal() {
      this.$bus.$emit("update-transaction-status-modal", {
        title: "Update Transaction Status",
        data: this.transaction,
        confirm: () => {
          const id = this.transaction.Prefix + this.transaction.TransactionID.toString().padStart(9, "0");
          this.getTransaction(id);
        },
      });
    },
    reversePayout() {
      this.$swal.fire({
        title: "Reverse payout",
        text: "Are you sure, want to reverse payout",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$swal.fire({
            title: "",
            text: "Please wait...",
            showConfirmButton: false,
            backdrop: true,
          });
          const payload = {
            Call: "ReversePayout",
            MerchantID: this.currentUser.ID,
            APIKey: this.currentUser.APIKey,
            targetMerchant: this.transaction.MerchantID,
            PayoutID: this.transaction.TransactionID,
            output: "json",
          };
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            this.$swal.close();
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", "Payout reverse request submitted successfully", "success");
                saveNotification("Payout reverse request submitted successfully");
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            this.$swal.close();
            console.log(error);
          });
        }
      });
    },
    launchUpdateTransactionLogsModal() {
      this.$bus.$emit("update-transaction-logs-modal", {
        title: "Update Transaction Logs",
        data: this.transaction,
        confirm: (comments, paymentProviderID) => {
          const data = (this.transaction.Prefix === "T") ? this.externalPaymentProviders : this.payoutTypes;
          const pp = this._.find(data, { ID: paymentProviderID });
          let externalPaymentProvider = this.transaction.ExternalPaymentProvider;
          if (pp) {
            externalPaymentProvider = pp.Name;
          }
          this.transaction = Object.assign({}, this.transaction, {
            Comments: comments,
            ExternalPaymentProvider: externalPaymentProvider,
          });
        },
      });
    },
    launchSwapModal(transaction, balance, amount) {
      this.$bus.$emit("swapModal", {
        record: transaction,
        targetAltCoinID: balance.id,
        selectedBalance: balance,
        balances: this.balanceList,
        amount,
        confirm: () => {
          this.refundTransaction = transaction;
          this.getWalletsBalance();
        },
      });
    },
    refund(transaction) {
      if (this.isRefundProcessing) {
        return;
      }
      const totalBalance = Number(this.balanceList.reduce((acc, i) => { return acc + Number(i.fiatvalue); }, 0));
      if (totalBalance <= 0) {
        this.$toast.fire("", "You have no balance to initiate a refund.", "error");
        return;
      }
      let isCryptoAddress = false;
      const parts = transaction.RefundAddress.split("-");
      let amount = transaction.Amount;
      const selectedAddress = {};
      parts.forEach(p => {
        p = p.split(":");
        if (p[0] === "amount") {
          amount = p[1];
        } else {
          selectedAddress[p[0]] = p[1];
        }
      });
      if (selectedAddress.address !== undefined && selectedAddress.tag !== undefined) {
        isCryptoAddress = true;
      }
      const balance = this._.find(this.balanceList, { id: Number(transaction.AltCoinID) });
      const targetCoin = this._.find(this.altCoins, { ID: Number(transaction.AltCoinID) });
      const totalRefundAmount = Number(amount) + Number(targetCoin.SendFee);
      if (balance && Number(balance.balance) < totalRefundAmount) {
        if (this.refundTransaction) {
          this.getWalletsBalance();
        } else {
          let refundAmount = Number(totalRefundAmount) - Number(balance.balance);
          refundAmount = refundAmount - Number(targetCoin.SendFee);
          this.launchSwapModal(transaction, balance, refundAmount);
        }
      } else {
        this.refundTransaction = null;
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$swal.fire({
          title: "Refund",
          text: "Are you sure want to refund?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          focusCancel: true,
          customClass: {
            container: "swal2-tailwind",
          },
        }).then(({ value }) => {
          if (value && value === true) {
            this.isRefundProcessing = true;
            if (isCryptoAddress) {
              const payload = {
                MerchantID: this.transaction.MerchantID,
                APIKey: this.transaction.APIKey,
                AltCoinID: this.transaction.AltCoinID,
                Amount: amount,
                coinAddress: selectedAddress.address,
                ChargingMethod: "fixed-fee",
                PayoutMonth: new Date().getMonth() + 1,
                CustomerReferenceNr: `refund-${this.transaction.TransactionID}`,
                TransactionTotal: 1,
                output: "json",
              };
              if (selectedAddress.tag !== "") {
                payload.tag = selectedAddress.tag;
              }
              this.$http.get(`${apiBaseUrl}/GetSendToAddress`, {
                params: payload,
              }).then(response => {
                this.isRefundProcessing = false;
                if (response.status === 200) {
                  this.$toast.fire("", response.data, "success");
                  this.saveNotification(response.data);
                }
              }).catch(error => {
                this.isRefundProcessing = false;
                if (error.data && (error.data.startsWith("Not enough balance") || error.data.indexOf("rejected") !== -1 || error.data.indexOf("has been disabled") !== -1)) {
                  this.$toast.fire("", error.data, "error");
                } else {
                  console.log(error.response);
                }
              });
            } else {
              const payload = {
                Call: "FiatPayout",
                MerchantID: this.transaction.MerchantID,
                APIKey: this.transaction.APIKey,
                AltCoinID: this.transaction.AltCoinID,
                Amount: amount,
                CustomerReferenceNr: `refund-${this.transaction.TransactionID}`,
                ...selectedAddress,
                output: "json",
              };
              this.$http.get(`${apiBaseUrl}/v2REAPI`, {
                params: payload,
              }).then(response => {
                this.isRefundProcessing = false;
                if (response.status === 200) {
                  if (typeof response.data === "string") {
                    let msg = "";
                    if (response.data.toLowerCase() === "ok") {
                      msg = response.data.replace(/^\w/, c => c.toUpperCase());
                      this.$toast.fire("", msg, "success");
                    } else {
                      msg = response.data.replace(/^\w/, c => c.toUpperCase());
                      this.$toast.fire("", msg, "error");
                    }
                    this.saveNotification(msg);
                  }
                }
              }).catch(error => {
                this.isRefundProcessing = false;
                console.log(error.response);
              });
            }
          }
        });
      }
    },
    getPaymentProviders() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=payment_providers`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        const data = response.data.data;
        this.$store.commit(SET_EXTERNAL_PAYMENT_PROVIDERS, data.externalPaymentProviders);
        this.$store.commit(SET_PAYOUT_TYPES, data.payoutTypes);
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.response.data);
      });
    },
    showExchangeDetail(id) {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
      const url = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${url}/`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          Call: "GetExchangeDetail",
          id: id,
        },
      }).then(response => {
        const data = response.data.data;
        this.$swal.close();
        this.$bus.$emit("exchange-detail-modal", {
          title: "Exchange Detail",
          data: data,
        });
      }).catch(error => {
        this.$swal.close();
        if (error.data) {
          this.$toast.fire("", error.response.data.message, "error");
        }
      });
    },
    getKey(key) {
      switch (key) {
        case "ExpiredDate":
          key = "Expired";
          break;
        case "transactionComments":
          key = "Comments";
      }
      return key;
    },
    getValue(key) {
      if (key === "Reopenable") {
        return this.transaction[key] ? 1 : 0;
      } else if (key === "Amount") {
        return toFixed(this.transaction[key]);
      }
      return this.transaction[key];
    },
    updateTransaction(key, label, type) {
      const options = getOptions(key);
      this.$bus.$emit("updateTransactionModal", {
        title: "Update " + label,
        valueLabel: label,
        table: this.transaction.Prefix === "T" ? "Transaction" : "Payout",
        record: {
          ID: this.transaction.TransactionID,
          Key: this.getKey(key),
          Value: this.getValue(key),
        },
        type,
        options,
        confirm: () => {
          const id = this.$route.params.id;
          if (id) {
            this.getTransaction(id, false);
          }
        },
      });
    },
    refreshTransaction() {
      if (!this.isRefreshable || this.isPaid || this.isExpired) {
        return;
      }
      this.isRefreshable = false;
      const url = process.env.VUE_APP_API_URL;
      this.$http.get(`${url}/v2REAPI`, {
        params: {
          Call: "txcheckcall",
          MerchantID: this.transaction.MerchantID,
          APIKey: "_",
          AltCoinID: this.transaction.AltCoinID,
          TransactionID: this.transaction.TransactionID,
          coinAddress: this.transaction.CoinAddress,
          output: "json",
        },
      }).then(response => {
        setTimeout(() => {
          this.isRefreshable = true;
        }, 60000);
        if (response.data && response.data.message) {
          this.$toast.fire("", response.data.message, "success");
          const id = this.$route.params.id;
          if (id) {
            this.getTransaction(id);
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    toggleTransactionBooleanFields(key, value) {
      const objKey = key === "TransactionConfirmed_Freeze" ? "TransactionConfirmedFreeze" : key;
      const payload = {
        table: "Transaction",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: this.transaction.TransactionID,
        field: key,
        value: Number(value) === 0 ? 1 : 0,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=UpdateTable`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.transaction = {
            ...this.transaction,
            [objKey]: payload.value,
          };
        }
      }).catch(error => {
        console.log(error);
      });
    },
    selectInvoiceCompany() {
      this.$bus.$emit("selectCompanyModal", {
        companies: this.pdfConfig,
        confirm: (company) => {
          this.generateInvoice(company);
        },
      });
    },
    async generateInvoice(company) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      // Logo
      const imgData = company.logo;
      doc.addImage(imgData, "PNG", 10, 16, company.logo_width, company.logo_height);

      // Company detail
      doc.setFontSize(12);
      let companyInfoY = 22;
      doc.text(company.name, company.company_info_x, companyInfoY);
      companyInfoY += 6;
      doc.text(company.address, company.company_info_x, companyInfoY);
      companyInfoY += 6;
      if (company.address2) {
        doc.text(company.address2, company.company_info_x, companyInfoY);
        companyInfoY += 6;
      }
      doc.text(`${company.postal_code}, ${company.city}`, company.company_info_x, companyInfoY);
      companyInfoY += 6;
      doc.text(company.country, company.company_info_x, companyInfoY);
      // Label
      doc.setFontSize(18);
      doc.text(this.transaction.Prefix === "T" ? "INVOICE" : "PAYOUT", 10, 56);
      // Customer data
      doc.setFontSize(12);
      doc.text(this.transaction.PayoutFullName, 10, 66);
      doc.text(this.transaction.PayoutAddress, 10, 72);
      doc.text(`${this.transaction.PayoutPostalCode}, ${this.transaction.PayoutCity}, ${this.transaction.PayoutState}`, 10, 78);
      doc.text(this.transaction.PayoutCountry, 10, 84);
      // Transaction data
      doc.text(this.transaction.Prefix === "T" ? "Invoice nr:" : "Payout nr:", company.company_info_x, 66);
      const id = this.transaction.Prefix + this.transaction.TransactionID.toString().padStart(9, "0");
      doc.text(id, 160, 66);
      // Commerce ID
      doc.text("Commerce ID:", company.company_info_x, 72);
      doc.text(company.commerceid, 160, 72);
      // Vat ID
      doc.text("VAT ID:", company.company_info_x, 78);
      doc.text(company.vatid, 160, 78);
      // Date
      doc.text("Date:", company.company_info_x, 84);
      const date = formatDate(this.transaction.CreatedOn, "DD MMM. YYYY");
      doc.text(date, 160, 84);

      doc.text(`This ${this.transaction.Prefix === "T" ? "invoice" : "payout"} ${id} has status: ${this.transaction.Status}`, 10, 96);
      let detail = "";
      if (["paid", "underpaid"].indexOf(this.transaction.Status.toLowerCase()) !== -1) {
        const type = this.transaction.ExternalTransactionProvider
          ? this.transaction.ExternalTransactionProvider : this.transaction.TransactionType;
        if (["swap", "xchange"].indexOf(type.toLowerCase()) === -1) {
          const amount = (this.transaction.InitialAmount - this.transaction.Amount).toFixed(8);
          detail = `\nFees deducted: ${amount} ${this.transaction.LongName}`;
        }
      }
      autoTable(doc, {
        styles: { fillColor: [0, 0, 0] },
        head: [["Detail", "Quantity", "Price"]],
        body: [
          [`${id}${detail}`, 1, `${this.transaction.Amount} ${this.transaction.LongName}`],
        ],
        startY: 106,
        margin: 10,
        columnStyles: {
          0: { cellWidth: 120 },
          1: { cellWidth: 30 },
          2: { cellWidth: 40 },
        },
      });

      autoTable(doc, {
        body: [
          ["Sub Total", `${this.transaction.Amount} ${this.transaction.LongName}`],
          ["VAT", "0%"],
          ["Total", `${this.transaction.Amount} ${this.transaction.LongName}`],
        ],
        startY: 130,
        margin: { left: 130, right: 10 },
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 40 },
        },
      });
      // Footer
      doc.line(10, 280, 200, 280);
      doc.text(`Contact: ${company.email} | Call ${company.phone}`, 52, 286);
      doc.save(this.transaction.Prefix === "T" ? "transaction.pdf" : "payout.pdf");
    },
    format(key) {
      const labels = {
        targetCurrency: "Target currency",
        accountHolderName: "Account holder name",
        legalType: "Legal type from",
        targetLegalType: "Legal type to",
        taxId: "Tax ID",
        accountNumber: "Account number",
        bsbCode: "Bsb code",
        ifscCode: "IFSC code",
        country: "Country",
        city: "City",
        postalCode: "Postal code",
        firstLine: "Address",
        abartn: "Routing number",
        bankCode: "Bank code",
        branchCode: "Branch code",
        phoneNumber: "Phone number",
        institutionNumber: "Institution no.",
        transitNumber: "Transit no.",
        rut: "Rut number",
        cardNumber: "Card number",
        prefix: "Prefix",
        sortCode: "Sort code",
        clabe: "Clabe",
        cpf: "Tax registration number (CPF)",
        swiftCode: "Swift code",
        idDocumentType: "ID document type",
        idDocumentNumber: "ID document type",
        altCoinID: "AltCoin",
        tag: "Tag / Memo",
        address: "Crypto Address",
      };
      return (labels[key]) ? labels[key] : key;
    },
    getCoinName(val) {
      const coin = this._.find(this.altCoins, { ID: Number(val) });
      return coin ? `${coin.Name} - ${coin.LongName}` : val;
    },
    getWalletsBalance() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          id: this.transaction.MerchantID,
          Call: "BalanceOverviewWEB",
          MerchantID: this.transaction.MerchantID,
          APIKey: this.transaction.APIKey,
          inputCurrency: "EUR",
          output: "json",
        },
      }).then(response => {
        if (response.status === 200) {
          this.balanceList = response.data;
          if (this.refundTransaction) {
            this.refund(this.refundTransaction);
          }
        }
      }).catch(error => {
        if (error?.response?.message) {
          saveNotification(error.response.message);
        }
        console.log(error);
      });
    },
    loadPdfConfig() {
      const baseUrl = window.location.protocol + "//" + window.location.host;
      const configFile = baseUrl + "/pdf_config.json";
      this.$http.get(`${configFile}`).then(response => {
        this.pdfConfig = response.data;
      }).catch(response => {
      });
    },
  },
  beforeMount() {
    this.isRefreshable = true;
    this.loadPdfConfig();
  },
  mounted() {
    const id = this.$route.params.id;
    if (id && id.toLowerCase() !== "all") {
      this.getTransaction(id);
    } else {
      this.$router.push({ name: "transactions" });
    }
    this.getPaymentProviders();
    this.$store.dispatch(TRANSFERWISE_CONFIG);
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style lang="scss" scoped>
.row {
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid #f4f4f4;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}

.wb {
  max-width: 320px;
  word-break: break-all;
  white-space: break-spaces;
}

.d-flex {
  display: flex;
}

.capitalize {
  text-transform: capitalize;
}
</style>
