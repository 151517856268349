<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static" data-keyboard="false"
    data-toggle="modal" id="bsModalRefundAddress" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="closeModal()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">Refund Address</h4>
        </div>
        <div class="modal-body" v-if="form">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">

              <ValidationProvider v-if="fiatValue" class="form-group" name="fiat amount" rules="required" tag="div"
                v-slot="{ errors }">
                <div class="flex align-items-center gap-4">
                  <input aria-label="Fiat amount" class="form-control" placeholder="Fiat amount" type="text"
                    @keyup="updateAmount()" v-model="fiatAmount">
                  <span style="width: 50px; text-align: right;">{{ fiatCurrency }}</span>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" name="amount" rules="required" tag="div" v-slot="{ errors }">
                <div class="flex align-items-center justify-between">
                  <label>Refund Amount</label>
                  <span v-if="transaction && transaction.Amount > 0.00000001" class="pointer"
                    @click="refundAllAmount()">Refund all Amount</span>
                </div>
                <div class="flex align-items-center gap-4">
                  <input aria-label="Refund Amount" class="form-control" placeholder="Refund Amount" type="text"
                    v-model="form.amount" @keyup="updateFiatAmount()">
                  <span v-if="transaction" style="width: 50px; text-align: right;">{{ transaction.LongName }}</span>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <div class="mb-2" v-if="selectedAddress !== null">
                <label class="block mb-2 font-semibold">Refund Information</label>
                <div class="flex align-items-start mb-8">
                  <div class="flex-grow-1 mr-3" style="word-break: break-all;">
                    <p v-for="(item, key) in selectedAddress" :key="key" class="mb-0">
                      {{ format(key) + ': ' + (key === 'altCoinID' ? getCoinName(item) : item) }}
                    </p>
                  </div>
                  <button
                    :disabled="isProcessing || !transaction.RefundAddress || transaction.RefundAddress.toLowerCase() === 'empty'"
                    type="button" class="btn btn-danger btn-flat" @click="removeRefundAddress()">
                    Remove
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-8">
                  <button :disabled="isProcessing" class="btn btn-success btn-flat mr-4" type="button"
                    @click="launchBankAddressBookModal()">
                    Bank Address Book
                  </button>
                  <button :disabled="isProcessing" class="btn btn-success btn-flat" type="button"
                    @click="launchCryptoAddressBookModal()">
                    Crypto Address Book
                  </button>
                </div>
                <div class="col-xs-4">
                  <button :disabled="isProcessing || selectedAddress === null"
                    class="btn btn-success btn-block btn-flat" type="submit">
                    <span v-if="!isProcessing">Add</span>
                    <span v-if="isProcessing">
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- Crypto Address Book Modal -->
    <CryptoAddressBookModal></CryptoAddressBookModal>
    <!-- Bank Address Book Modal -->
    <BankAddressBookModal></BankAddressBookModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification, toFixed } from "@/lib/utils";
import find from "lodash/find";
import CryptoAddressBookModal from "@/components/merchant-detail/modals/CryptoAddressBookModal";
import BankAddressBookModal from "@/components/merchant-detail/modals/BankAddressBookModal";

export default {
  name: "RefundAddressModal",
  components: {
    CryptoAddressBookModal,
    BankAddressBookModal,
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_API_URL,
      transaction: null,
      form: {
        amount: "",
      },
      selectedAddress: null,
      fiatValue: 0,
      fiatAmount: null,
      merchant: null,
      addressesList: {
        crypto: [],
        transferwise: [],
      },
      confirmHook: () => {
      },
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters([
      "altCoins",
      "jwtToken",
      "transferWiseConfig",
    ]),
    fiatCurrency() {
      return this.transaction.InputCurrency || "EUR";
    },
  },
  methods: {
    closeModal() {
      $("#bsModalRefundAddress").modal("hide");
    },
    toFixed(val) {
      return toFixed(val);
    },
    fiatLookup(amount) {
      if (amount) {
        this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "Fiatlookup",
            MerchantID: this.merchant.ID,
            APIKey: this.merchant.APIKey,
            AltCoinID: this.transaction.AltCoinID,
            Amount: amount,
            FiatLookup: 1,
            FiatMoney: this.transaction.InputCurrency || "EUR",
            output: "json",
          },
        }).then(response => {
          if (response.data && response.data.length > 0) {
            this.fiatValue = response.data[0].OutputValue;
            if (this.fiatAmount === null && this.form.amount > 0) {
              this.updateFiatAmount();
            }
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    refundAllAmount() {
      this.form.amount = this.toFixed(this.transaction.Amount);
      this.updateFiatAmount();
    },
    updateAmount() {
      if (this.fiatAmount && this.fiatAmount > 0) {
        this.form.amount = this.toFixed(this.fiatValue * this.fiatAmount);
      } else {
        this.form.amount = "";
      }
    },
    updateFiatAmount() {
      if (this.form) {
        if (this.form.amount > 0 && this.form.amount !== "") {
          this.fiatAmount = this.toFixed((1 / this.fiatValue) * this.form.amount);
        } else {
          this.fiatAmount = null;
        }
      }
    },
    format(key) {
      const labels = {
        targetCurrency: "Target currency",
        accountHolderName: "Account holder name",
        legalType: "Legal type from",
        targetLegalType: "Legal type to",
        taxId: "Tax ID",
        accountNumber: "Account number",
        bsbCode: "Bsb code",
        ifscCode: "IFSC code",
        country: "Country",
        city: "City",
        postalCode: "Postal code",
        firstLine: "Address",
        abartn: "Routing number",
        bankCode: "Bank code",
        branchCode: "Branch code",
        phoneNumber: "Phone number",
        institutionNumber: "Institution no.",
        transitNumber: "Transit no.",
        rut: "Rut number",
        cardNumber: "Card number",
        prefix: "Prefix",
        sortCode: "Sort code",
        clabe: "Clabe",
        cpf: "Tax registration number (CPF)",
        swiftCode: "Swift code",
        idDocumentType: "ID document type",
        idDocumentNumber: "ID document type",
        altCoinID: "AltCoin",
        tag: "Tag / Memo",
        address: "Crypto Address",
      };
      return (labels[key]) ? labels[key] : key;
    },
    getCoinName(val) {
      const coin = find(this.altCoins, { ID: Number(val) });
      return coin ? `${coin.Name} - ${coin.LongName}` : val;
    },
    getMerchantDetail(id) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: id,
        },
      }).then(response => {
        if (response.status === 200) {
          this.merchant = Object.assign({}, response.data.data);
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    submit() {
      if (this.selectedAddress) {
        let info = `amount:${this.form.amount}`;
        Object.keys(this.selectedAddress).forEach(k => {
          if (k !== "description") {
            info += `-${k}:${this.selectedAddress[k]}`;
          }
        });
        this.confirmHook(info);
        this.closeModal();
      }
    },
    removeRefundAddress() {
      this.$swal.fire({
        title: "Refund Address",
        text: "Are you sure you want to remove refund address?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
        customClass: {
          container: "swal2-tailwind",
        },
      }).then(({ value }) => {
        if (value && value === true) {
          this.selectedAddress = null;
          this.$toast.fire("", "Refund address removed successfully", "success");
          this.confirmHook();
        }
      });
    },
    launchBankAddressBookModal() {
      this.$bus.$emit("bank-address-book-modal", {
        title: "Bank Address Book",
        data: this.merchant,
        isSelector: true,
        confirm: (address) => {
          if (address) {
            const transferWiseConfig = this._.cloneDeep(this.transferWiseConfig);
            const config = this._.find(transferWiseConfig, { currency: address.currency });
            let obj = {
              targetCurrency: address.currency,
              accountHolderName: address.accountHolderName,
              legalType: address.legalType ? address.legalType : address.details.legalType,
              targetLegalType: address.targetLegalType,
            };
            if (address.details.accountType) {
              obj = Object.assign(obj, {
                accountType: address.details.accountType,
              });
            }
            const required = config.required.split(",");
            required.forEach(param => {
              const value = (address.details[param])
                ? address.details[param] : address.details.address[param];
              obj = Object.assign(obj, { [param]: value });
            });

            this.selectedAddress = obj;
          }
        },
      });
    },
    launchCryptoAddressBookModal() {
      this.$bus.$emit("crypto-address-book-modal", {
        title: "Crypto Address Book",
        data: this.merchant,
        isSelector: true,
        confirm: (address) => {
          if (address) {
            this.selectedAddress = address;
          }
        },
      });
    },
  },
  mounted() {
    this.$bus.$on("updateRefundAddressModal", (args) => {
      this.transaction = args.data;
      this.merchant = {
        ID: this.transaction.MerchantID,
        APIKey: this.transaction.APIKey,
      };
      this.getMerchantDetail(this.transaction.MerchantID);
      this.confirmHook = args.confirm;
      this.selectedAddress = null;
      // Reset
      this.fiatValue = 0;
      this.fiatAmount = null;
      this.fiatLookup(1);
      this.form = {
        amount: "",
      };
      if (this.transaction.RefundAddress && this.transaction.RefundAddress.toLowerCase() !== "empty") {
        this.selectedAddress = {};
        const parts = this.transaction.RefundAddress.split("-");
        parts.forEach(p => {
          p = p.split(":");
          if (p[0] === "amount") {
            this.form.amount = p[1];
          } else {
            this.selectedAddress[p[0]] = p[1];
          }
        });
      }
      // this.rules = {
      //   amount: ['required', 'decimal:8', 'minValue:0.00000001', 'maxValue:' + this.transaction.Amount]
      // }
      // Show modal
      $("#bsModalRefundAddress").modal("show");
    });
  },
};
</script>

<style scoped lang="scss">
.align-items-start {
  align-items: flex-start;
}
</style>
