<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static" data-keyboard="false"
    data-toggle="modal" id="bsModalSwap" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">
            Swap
            <img v-if="sourceCoin" :src="iconURL" alt="" class="w-24 h-24" />
          </h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning">
            <p>In order for us to refund the amount we need to swap first. You currently do not have enough balance in
              this currency to make the refund.</p>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" rules="required" name="altCoinId" tag="div">
                <div class="flex items-center justify-between">
                  <label>Swap From</label>
                  <span v-if="sourceCoin" class="pointer text-sm" @click="swapAllBalance()">Swap all balance</span>
                </div>
                <select class="form-control" v-model="form.AltCoinID" @change="fiatLookup()">
                  <option v-for="(altCoin, index) in balanceList" :key="index" :value="altCoin.id">
                    {{ `${altCoin.name} ${altCoin.longname} - ${altCoin.balance}` }}
                  </option>
                </select>
                <span v-if="balanceList.length === 0">No enough balance, please refresh</span>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="form-group">
                <div class="mb-2">
                  <label class="">Amount <span v-if="sourceCoin">({{ sourceCoin.longname }})</span></label>
                </div>
                <input type="text" v-model="form.Amount" class="form-control" @keyup="calculateAmount('source')">
              </div>
              <div class="form-group">
                <div class="mb-2">
                  <label>Swap To</label>
                </div>
                <p v-if="targetCoin">{{ `${targetCoin.Name} (${targetCoin.LongName})` }}</p>
              </div>

              <ValidationProvider v-slot="{ errors }" class="form-group" rules="required" name="TargetAmount" tag="div">
                <div class="flex items-center justify-between mb-2">
                  <label>
                    <span>Amount</span>&nbsp;
                    <span v-if="targetCoin">({{ targetCoin.LongName }})</span>
                  </label>
                </div>
                <input type="text" v-model="form.TargetAmount" class="form-control" @keyup="calculateAmount('target')">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Swap</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { saveNotification, toFixed, generateIconUrl } from "@/lib/utils";

export default {
  name: "SwapModal",
  data() {
    return {
      isLoading: false,
      apiBaseUrl: process.env.VUE_APP_API_URL,
      form: {
        AltCoinID: "",
        TargetAltCoinID: "",
        Amount: "",
        TargetAmount: "",
        output: "json",
      },
      amount: 0,
      isReverse: false,
      fiatValue: 0,
      isProcessing: false,
      targetCoin: null,
      sourceCoin: null,
      balanceList: [],
      record: null,
      iconURL: "",
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "currentUser",
      "altCoins",
    ]),
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        if (newVal.TargetAltCoinID) {
          this.targetCoin = this._.find(this.altCoins, { ID: newVal.TargetAltCoinID });
        }
        if (oldVal !== undefined && newVal.AltCoinID !== oldVal?.AltCoinID) {
          this.sourceCoin = this._.find(this.balanceList, { id: newVal.AltCoinID });
          if (this.sourceCoin) {
            this.iconURL = generateIconUrl(this.sourceCoin.name);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    swapAllBalance() {
      this.form.Amount = toFixed(this.sourceCoin.balance);
      this.calculateAmount("source");
    },
    fiatLookup() {
      if (!this.sourceCoin || !this.targetCoin) {
        return;
      }
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Fiatlookup",
          MerchantID: this.record.MerchantID || "1",
          APIKey: this.record.APIKey || "_",
          AltCoinID: this.isReverse ? this.targetCoin.ID : this.sourceCoin.id,
          Amount: 1,
          FiatLookup: 0,
          FiatMoney: this.isReverse ? this.sourceCoin.shortname : this.targetCoin.ShortName,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.fiatValue = Number(response.data[0].OutputValue);
          this.calculateAmount();
        } else {
          this.isReverse = true;
          this.fiatLookup();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    calculateAmount(key = null) {
      if (this.fiatValue) {
        if (!key) {
          let targetAmount = 0;
          let sourceAmount = this.amount + Number(this.targetCoin.SendFee);
          sourceAmount += sourceAmount * 0.05;
          targetAmount = sourceAmount;
          if (this.isReverse) {
            sourceAmount = sourceAmount * this.fiatValue;
          } else {
            sourceAmount = sourceAmount / this.fiatValue;
          }
          this.form = {
            ...this.form,
            Amount: toFixed(sourceAmount),
            TargetAmount: toFixed(targetAmount),
          };
        } else {
          if (!key) {
            key = this.form.Amount ? "source" : "target";
          }
          if (!this.isReverse) {
            if (key === "source") {
              if (this.form.Amount !== "") {
                this.form.TargetAmount = toFixed(this.fiatValue * this.form.Amount);
              }
            } else {
              if (this.form.TargetAmount !== "") {
                this.form.Amount = toFixed(this.form.TargetAmount / this.fiatValue);
              }
            }
          } else {
            if (key === "source") {
              if (this.form.Amount !== "") {
                this.form.TargetAmount = toFixed(this.form.Amount / this.fiatValue);
              }
            } else {
              if (this.form.TargetAmount !== "") {
                this.form.Amount = toFixed(this.form.TargetAmount * this.fiatValue);
              }
            }
          }
        }
      }
    },
    onSubmit() {
      if (this.form.Amount > Number(this.sourceCoin.balance)) {
        this.$toast.fire("", "You do not have enough balance in this coin to perform the SWAP", "error");
        return;
      }
      this.isProcessing = true;
      let payload = {
        Call: "COIN-ON",
        MerchantID: this.record.MerchantID,
        APIKey: this.record.APIKey,
        AltCoinID: this.form.TargetAltCoinID,
        output: "json",
      };
      // Enable coin
      this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        if (response.status === 200 && response.data.toLowerCase() === "ok") {
          payload = this.form;
          payload = Object.assign(payload, {
            Call: "SWAP",
            MerchantID: this.record.MerchantID,
            APIKey: this.record.APIKey,
            V2: "",
          });
          this.$http.get(`${this.apiBaseUrl}/v2REAPI`, {
            params: payload,
          }).then(response => {
            this.isProcessing = false;
            if (response.status === 200 && response.data.result === "success") {
              this.confirmHook();
              this.$toast.fire("", response.data.message, "success");
              this.close();
            } else {
              this.$toast.fire("", response.data.message, "error");
            }
            saveNotification(response.data.message);
          }).catch(error => {
            this.isProcessing = false;
            console.log(error.response);
          });
        }
      }).catch(error => {
        this.isProcessing = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalSwap").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("swapModal", (args) => {
      this.confirmHook = args.confirm;
      this.record = args.record;
      this.targetCoin = args.selectedBalance || null;
      this.amount = args.amount ? Number(args.amount) : "";
      this.selectedAltCoinID = args.targetAltCoinID;
      this.sourceCoin = null;
      this.targetCoin = null;
      this.isReverse = false;
      this.targetCoin = this._.find(this.altCoins, { ID: Number(args.targetAltCoinID) });
      this.form = {
        TargetAltCoinID: args.targetAltCoinID || "",
        AltCoinID: "",
        TargetAmount: "",
        output: "json",
      };
      this.balanceList = args.balances.filter(b => b.id !== Number(this.form.TargetAltCoinID) && b.id !== 0 && Number(b.balance) > 0);
      if (this.balanceList.length) {
        this.sourceCoin = this.balanceList[0];
        this.form = {
          ...this.form,
          AltCoinID: this.sourceCoin.id,
        };
      }
      this.fiatLookup();
      $("#bsModalSwap").modal("show");
    });
  },
};
</script>
<style lang="scss" scoped>
.justify-between {
  justify-content: space-between;
}
</style>
