<template>
  <div id="bsModalExchangeDetail" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog modal-lg" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <template v-if="record">
            <div class="row">
              <div class="col-md-3">ID</div>
              <div class="col-md-9">{{ record.ID }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Pair ID</div>
              <div class="col-md-9">{{ record.PairID }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">ExID</div>
              <div class="col-md-9">{{ record.ExID }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Name</div>
              <div class="col-md-9">{{ record.Name }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">High</div>
              <div class="col-md-9">{{ record.High }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Low</div>
              <div class="col-md-9">{{ record.Low }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Avg.</div>
              <div class="col-md-9">{{ record.Avg }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Vol.</div>
              <div class="col-md-9">{{ record.Vol }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Vol. Cur.</div>
              <div class="col-md-9">{{ record.Vol_Cur }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Last</div>
              <div class="col-md-9">{{ record.Last }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Buy</div>
              <div class="col-md-9">{{ record.Buy }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Sell</div>
              <div class="col-md-9">{{ record.Sell }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Updated</div>
              <div class="col-md-9">{{ fd(record.Updated) }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Server Time</div>
              <div class="col-md-9">{{ fd(record.Server_Time) }}</div>
            </div>
          </template>
          <template v-else>
            <div class="text-center">No record found!</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment-timezone";

export default {
  name: "ExchangeDetailModal",
  data() {
    return {
      title: "",
      record: null,
    };
  },
  methods: {
    fd(val) {
      if (val) {
        return moment(val * 1000).format("YYYY-MM-DD hh:mm:ss A");
      }
      return val;
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalExchangeDetail").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("exchange-detail-modal", (args) => {
      this.title = args.title;
      this.record = args.data;
      $("#bsModalExchangeDetail").modal("show");
    });
  },
};
</script>

<style scoped></style>
