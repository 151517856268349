<template>
  <div>
    <i class="fa fa-spinner ml-8 text-blue" v-if="status === 'waiting'" ></i>
    <i class="fa fa-check-circle ml-8 text-green" v-if="status === 'paid'" ></i>
    <i class="fa fa-check-circle ml-8 text-yellow" v-if="status === 'underpaid'" ></i>
    <i class="fa fa-times-circle ml-8 text-red" v-if="status === 'expired'" ></i>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    transaction: Object,
    refreshing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.transaction && this.transaction.Status.toLowerCase();
    },
  },
};
</script>
